typeof window !== "undefined" ? require("boxicons") : null;
import PropTypes from "prop-types";
import React from "react";

const Card = ({
  className,
  bestSeller,
  icon,
  title,
  description,
  children
}) => {
  return (
    <div className={`${className}`}>
      {bestSeller && <div className="card-extra text-center">Mais vendido</div>}
      <div className={`card-container ${!bestSeller && "mt-8"}`}>
        <box-icon name={icon} color="#e57668" size="lg" />
        <h2 className="my-3 font-bold">{title}</h2>
        <p className="text-gray mb-5">{description}</p>
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  bestSeller: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default Card;
